exports.wrapPageElement = ({ element }) => {
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    const isLoading = typeof window.innerWidth === "undefined";
    // TODO: add loader
    if (isLoading) {
      return null;
    }
  }

  return element;
};
